import { connect } from 'react-redux'
import { Map } from 'immutable'
import {
  setAvailableProjectTags,
  setProjectTags,
  createNewProjectTag,
  resetCreateProjectTagStatusMessages,
  types,
} from '../../../../actions/projects/form_actions'
import { createLoadingSelector } from '../../../../libs/requests_status_handlers'

const loadingSelector = createLoadingSelector([types.CREATE_NEW_PROJECT_TAG, types.CREATE_NEW_FINANCE_TAG])

export default connect(
  (state: Map<string, any>) => ({
    availableProjectTags: state.getIn(['projects', 'form', 'availableProjectTags']).toJS(),
    projectTags: state.getIn(['projects', 'form', 'projectTags']).toJS(),
    isFetching: loadingSelector(state),
    createProjectTagError: state.getIn(['projects', 'form', 'createProjectTag', 'error']),
    createProjectTagSuccess: state.getIn(['projects', 'form', 'createProjectTag', 'success']),
  }),
  {
    setAvailableProjectTags,
    setProjectTags,
    createNewProjectTag,
    resetCreateProjectTagStatusMessages,
  }
)
