import { connect } from 'react-redux'
import { Map } from 'immutable'
import {
  setAvailableFinanceTags,
  setFinanceTags,
  createNewFinanceTag,
  resetCreateFinanceTagStatusMessages,
  types,
} from '../../../../actions/projects/form_actions'
import { createLoadingSelector } from '../../../../libs/requests_status_handlers'

const loadingSelector = createLoadingSelector([types.CREATE_NEW_FINANCE_TAG])

export default connect(
  (state: Map<string, any>) => ({
    availableFinanceTags: state.getIn(['projects', 'form', 'availableFinanceTags']).toJS(),
    financeTags: state.getIn(['projects', 'form', 'financeTags']).toJS(),
    isFetching: loadingSelector(state),
    createFinanceTagError: state.getIn(['projects', 'form', 'createFinanceTag', 'error']),
    createFinanceTagSuccess: state.getIn(['projects', 'form', 'createFinanceTag', 'success']),
  }),
  {
    setAvailableFinanceTags,
    setFinanceTags,
    createNewFinanceTag,
    resetCreateFinanceTagStatusMessages,
  }
)
