import * as React from 'react'
import { Provider } from 'react-redux'
import store from '../../../../store'
import ProjectTagsSelector from './project_tags_selector'

type ProjectTagsSelectorProps = {
  name: string
  value: { value: string; label: string }[]
  options: { value: string; label: string }[]
  canCreateNew: boolean
  buttonTitle: string
  windowTitle: string
}
export default function ProjectTagsSelectorApp(props: ProjectTagsSelectorProps) {
  return (
    <Provider store={store}>
      <ProjectTagsSelector {...props} />
    </Provider>
  )
}
