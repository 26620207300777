import * as React from 'react'
import { useEffect } from 'react'
import connect from './connect'
import SelectorWithPopup from '../selector_with_popup'
import CreateProjectTagForm from './create_project_tag_form'

type ProjectTagsSelectorProps = {
  name: string
  value: { value: string; label: string }[]
  options: { value: string; label: string }[]
  canCreateNew: boolean
  buttonTitle: string
  windowTitle: string

  // Redux props
  isFetching: boolean
  availableProjectTags: any[]
  projectTags: any[]
  setAvailableProjectTags: (tags: any[]) => void
  setProjectTags: (tags: any[]) => void
  createNewProjectTag: (data: any) => void
  createProjectTagError: string
  createProjectTagSuccess: string
  resetCreateProjectTagStatusMessages: () => void
}

function ProjectTagsSelector(props: ProjectTagsSelectorProps) {
  const {
    value,
    options,
    name,
    isFetching,
    canCreateNew,
    projectTags,
    setProjectTags,
    setAvailableProjectTags,
    availableProjectTags,
    createNewProjectTag,
    resetCreateProjectTagStatusMessages,
    createProjectTagError,
    createProjectTagSuccess,
    buttonTitle,
    windowTitle,
  } = props

  const [data, setData] = React.useState({
    name: '',
    description: '',
    type: 'Tags::ProjectTag',
  })

  useEffect(() => {
    setAvailableProjectTags(options)
    setProjectTags(value)
  }, [])

  return (
    <SelectorWithPopup
      name={name}
      values={projectTags}
      setValues={setProjectTags}
      options={availableProjectTags}
      canCreateNew={canCreateNew}
      isLoading={isFetching}
      renderForm={(formRef) => <CreateProjectTagForm data={data} setData={setData} formRef={formRef} namePrefix="project_tags" />}
      onSubmit={() => createNewProjectTag(data)}
      resetStatusMessages={resetCreateProjectTagStatusMessages}
      errorMessage={createProjectTagError}
      successMessage={createProjectTagSuccess}
      buttonTitle={buttonTitle}
      windowTitle={windowTitle}
      windowId="create-project-tag"
    />
  )
}

export default connect(ProjectTagsSelector)
