import * as React from 'react'
import { useEffect } from 'react'
import HiddenInputs from '../../hidden_inputs'
import Select from '../../base_react_select'
import connect from './connect'
import CreateServiceCompanyButton from './create_service_company_button'

type ServiceCompaniesSelectorProps = {
  companyId: number
  canCreateServiceCompany: boolean
  value: { value: string; label: string }[]
  options: { value: number; label: string }[]
  name: string
  addressCountryOptions: { value: string; label: string }[]
  // Redux props
  availableVendors: { value: string; label: string }[]
  vendors: { value: string; label: string }[]
  setAvailableVendors: (options: { value: number; label: string }[]) => void
  setVendors: (value: any) => void
  isFetching: boolean
  createNewServiceCompany: (data: any) => void
  createServiceCompanyError: string
  createServiceCompanySuccess: string
  resetCreateServiceCompanyStatusMessages: () => void
}

const { I18n } = window as any

function ServiceCompaniesSelector(props: ServiceCompaniesSelectorProps) {
  const {
    value,
    options,
    name,
    companyId,
    canCreateServiceCompany,
    addressCountryOptions,
    availableVendors,
    vendors,
    setAvailableVendors,
    setVendors,
    isFetching,
    createNewServiceCompany,
    createServiceCompanySuccess,
    createServiceCompanyError,
    resetCreateServiceCompanyStatusMessages,
  } = props
  useEffect(() => {
    if (options !== undefined) {
      setAvailableVendors(options)
    }
  }, [options])

  useEffect(() => {
    if (value !== undefined) {
      setVendors(value)
    }
  }, [value])

  return (
    <div className="d-flex">
      <div className="service-companies-selector-select flex-grow-1">
        <Select isMulti value={vendors} options={availableVendors} 
                  onChange={(opt) => {
                    setVendors(opt)
                  }} />
        <HiddenInputs items={vendors} name={name} />
      </div>
      {canCreateServiceCompany && (
        <div className="ml-3">
          <CreateServiceCompanyButton
            companyId={companyId}
            addressCountryOptions={addressCountryOptions}
            createNewServiceCompany={createNewServiceCompany}
            isFetching={isFetching}
            resetCreateServiceCompanyStatusMessages={resetCreateServiceCompanyStatusMessages}
            createServiceCompanyError={createServiceCompanyError}
            createServiceCompanySuccess={createServiceCompanySuccess}
            buttonTitle={I18n.t('project_tickets.create_service_company_window.button')}
            windowTitle={I18n.t('project_tickets.create_service_company_window.window_title')}
          />
        </div>
      )}
    </div>
  )
}

export default connect(ServiceCompaniesSelector)
