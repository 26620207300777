import { connect } from 'react-redux'
import { Map } from 'immutable'
import {
  dynamicLoadInstallers,
  createNewInstaller,
  resetCreateInstallerStatusMessages,
  setAvailableInstallers,
  setAvailableVendors,
  setInstallers,
  types,
} from '../../../../actions/projects/form_actions'
import { createLoadingSelector } from '../../../../libs/requests_status_handlers'

const loadingSelector = createLoadingSelector([types.CREATE_NEW_INSTALLER])

export default connect(
  (state: Map<string, any>) => ({
    createInstallerError: state.getIn(['projects', 'form', 'createInstaller', 'error']),
    createInstallerSuccess: state.getIn(['projects', 'form', 'createInstaller', 'success']),
    availableInstallers: state.getIn(['projects', 'form', 'availableInstallers']).toJS(),
    availableVendors: state.getIn(['projects', 'form', 'availableVendors']).toJS(),
    installers: state.getIn(['projects', 'form', 'installers']).toJS(),
    isFetching: loadingSelector(state),
    vendors: state.getIn(['projects', 'form', 'vendors']).toJS(),
  }),
  {
    createNewInstaller,
    resetCreateInstallerStatusMessages,
    setAvailableInstallers,
    setInstallers,
    setAvailableVendors,
    dynamicLoadInstallers,
  }
)
