import * as React from 'react'
import { Provider } from 'react-redux'
import store from '../../../../store'
import FinanceTagsSelector from './finance_tags_selector'

type FinanceTagsSelectorProps = {
  name: string
  value: { value: string; label: string }[]
  options: { value: string; label: string }[]
  canCreateNew: boolean
  buttonTitle: string
  windowTitle: string
}
export default function FinanceTagsSelectorApp(props: FinanceTagsSelectorProps) {
  return (
    <Provider store={store}>
      <FinanceTagsSelector {...props} />
    </Provider>
  )
}
