import * as React from 'react'
import { Provider } from 'react-redux'
import store from '../../../../store'
import InstallersSelector from './installers_selector'

type Option = {
  value: number
  label: string
}

type InstallersSelectorAppProps = {
  availableVendors: Option[]
  canCreateInstaller: boolean
  buttonTitle: string
  windowTitle: string
  name: string
  options: Option[]
  value: Option[]
  multi: boolean
  companyId: number
}

function InstallersSelectorApp(props: InstallersSelectorAppProps) {
  const { availableVendors, canCreateInstaller, buttonTitle, windowTitle, name, options, value, multi, companyId } = props
  return (
    <Provider store={store}>
      <InstallersSelector
        initialAvailableVendors={availableVendors}
        canCreateInstaller={canCreateInstaller}
        buttonTitle={buttonTitle}
        windowTitle={windowTitle}
        name={name}
        options={options}
        value={value}
        multi={multi}
        companyId={companyId}
      />
    </Provider>
  )
}

export default InstallersSelectorApp
