import * as React from 'react'
import { useState, useEffect, useRef, ReactElement } from 'react'
import SmModalWindow from '../../modal_window'
import Spinner from '../../spinner'
import I18n from '../../../../libs/i18n'

type PopupButtonProps = {
  onSubmit: () => void
  isLoading: boolean

  resetStatusMessages: () => void
  errorMessage: string
  successMessage: string
  renderForm: (formRef: any) => ReactElement

  buttonTitle: string
  windowTitle: string
  windowId: string
}
export default function PopupButton(props: PopupButtonProps) {
  const {
    onSubmit,
    isLoading,
    resetStatusMessages,
    errorMessage,
    successMessage,
    buttonTitle,
    windowTitle,
    renderForm,
    windowId,
  } = props

  const [dialogOpened, setDialogOpened] = useState(false)

  useEffect(() => {
    if (dialogOpened === false) {
      resetStatusMessages()
    }
  }, [dialogOpened])

  const formRef = useRef(null)

  const handleSave = () => {
    const f = formRef.current
    const valid = f.checkValidity()
    if (!valid) {
      f.reportValidity()
    } else {
      onSubmit()
    }
    return valid
  }

  const modalContent = (
    <>
      {errorMessage && <div className="text-danger text-center">{errorMessage}</div>}
      {successMessage && <div className="text-success text-center">{successMessage}</div>}
      {!errorMessage && !successMessage && <div className="text-left">{renderForm(formRef)}</div>}
    </>
  )

  const modalButtons = (
    <div className="buttons-container">
      <button
        className="btn btn-primary"
        type="button"
        disabled={isLoading || !!errorMessage || !!successMessage}
        onClick={handleSave}
      >
        {isLoading ? <Spinner className="ml-3 mr-3" viewType="inline" size="1" /> : I18n.t('application.actions.save')}
      </button>
    </div>
  )

  return (
    <>
      <button className="btn btn-outline-secondary w-100" type="button" onClick={() => setDialogOpened(true)}>
        <i className="fa fa-icon fa-plus mr-2" />
        {buttonTitle}
      </button>
      <SmModalWindow
        title={windowTitle}
        visible={dialogOpened}
        onClose={(e) => {
          setDialogOpened(false)
        }}
        content={modalContent}
        buttons={modalButtons}
        windowID={windowId}
      />
    </>
  )
}
