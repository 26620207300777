import * as React from 'react'
import { ReactElement } from 'react'
import Select from '../../base_react_select'
import HiddenInputs from '../../hidden_inputs'
import PopupButton from './popup_button'

type SelectorWithPopupProps = {
  name: string
  values: { value: string; label: string }[]
  setValues: (values: { value: string; label: string }[]) => void
  options: { value: string; label: string }[]
  canCreateNew: boolean

  isLoading: boolean
  renderForm: (formRef: any) => ReactElement
  onSubmit: () => void
  resetStatusMessages: () => void
  errorMessage: string
  successMessage: string

  buttonTitle: string
  windowTitle: string
  windowId: string
}
export default function SelectorWithPopup(props: SelectorWithPopupProps) {
  const {
    values,
    setValues,
    options,
    name,
    canCreateNew,
    isLoading,
    onSubmit,
    resetStatusMessages,
    errorMessage,
    successMessage,
    renderForm,
    buttonTitle,
    windowTitle,
    windowId,
  } = props


  return (
    <div className="d-flex">
      <div className="flex-grow-1">
        <Select isMulti value={values} options={options} onChange={setValues} />
        <HiddenInputs items={values} name={name} />
      </div>
      {canCreateNew && (
        <div className="ml-3">
          <PopupButton
            onSubmit={onSubmit}
            isLoading={isLoading}
            resetStatusMessages={resetStatusMessages}
            errorMessage={errorMessage}
            successMessage={successMessage}
            renderForm={renderForm}
            buttonTitle={buttonTitle}
            windowTitle={windowTitle}
            windowId={windowId}
          />
        </div>
      )}
    </div>
  )
}
