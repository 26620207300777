import _ from 'lodash'

class I18n {
  static t(key: string, params = {}) {
    let result = window.I18n.translations[key]
    if (_.isEmpty(result)) return key
    if (!_.isEmpty(params)) {
      _.each(params, (value: string, k: string) => {
        const pattern = new RegExp(`%{${k}}`, 'g')
        result = result.replace(pattern, value)
      })
    }
    return result
  }
}

export default I18n