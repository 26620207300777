import { fromJS, List } from 'immutable'
import { types } from '../../actions/projects/form_actions'

const makeItemSelected = (state, listKey, itemId) => {
  const items = state.get(listKey).reduce((result, item) => {
    result = result.push(item.set('selected', item.get('value') == itemId))
    return result
  }, List([]))

  return state.set(listKey, items)
}

function reducer(state, action) {
  const { type, ...data } = action

  switch (type) {
    case types.INIT_COMPANY_LIST:
      return state.set('companies', fromJS(action.companies))
    case types.INIT_GROUP_SELECTOR_DATA:
      return state.merge(fromJS(data))
    case types.LOAD_PROJECT_GROUPS + '_REQUEST':
      return makeItemSelected(state.set('groups', List([])), 'companies', action.companyId).set('fieldsHtml', '')
    case types.LOAD_PROJECT_GROUPS:
      const groups = action.data.groups
      return makeItemSelected(state.set('groups', fromJS(groups)), 'companies', action.data.company_id)
    case types.LOAD_ADDITIONAL_FORM_FIELDS + '_REQUEST':
      return makeItemSelected(state, 'groups', action.groupId)
    case types.LOAD_ADDITIONAL_FORM_FIELDS:
      return state.set('fieldsHtml', action.data.form_fields)
    // ServiceCompany input
    case `${types.CREATE_NEW_SERVICE_COMPANY}_FAILURE`:
      return state.setIn(['createServiceCompany', 'error'], action.error.message)
    case types.CREATE_NEW_SERVICE_COMPANY:
      const newCompany = action.data.data
      return state
        .setIn(['createServiceCompany', 'success'], action.data.message)
        .set(
          'availableVendors',
          state.get('availableVendors').push(
            fromJS({
              value: newCompany.id,
              label: newCompany.name,
            })
          )
        )
        .set(
          'vendors',
          state.get('vendors').push(
            fromJS({
              value: newCompany.id,
              label: newCompany.name,
            })
          )
        )
    case types.RESET_CREATE_SERVICE_COMPANY_STATUS_MESSAGES:
      return state.setIn(['createServiceCompany', 'error'], null).setIn(['createServiceCompany', 'success'], null)
    case types.SET_AVAILABLE_VENDORS:
      return state.set('availableVendors', fromJS(action.vendors))
    case types.SET_VENDORS:
      return state.set('vendors', fromJS(action.vendors))
    // Installers input
    case types.CREATE_NEW_INSTALLER + '_FAILURE':
      return state.setIn(['createInstaller', 'error'], action.error.message)
    case types.CREATE_NEW_INSTALLER:
      const newInstaller = action.data.user
      return state
        .setIn(['createInstaller', 'success'], action.data.message)
        .set(
          'availableInstallers',
          state.get('availableInstallers').push(
            fromJS({
              value: newInstaller.id,
              label: newInstaller.name,
            })
          )
        )
        .set(
          'installers',
          state.get('installers').push(
            fromJS({
              value: newInstaller.id,
              label: newInstaller.name,
            })
          )
        )
    case types.RESET_CREATE_INSTALLER_STATUS_MESSAGES:
      return state.setIn(['createInstaller', 'error'], null).setIn(['createInstaller', 'success'], null)
    case types.SET_AVAILABLE_INSTALLERS:
      return state.set('availableInstallers', fromJS(action.installers))
    case types.SET_INSTALLERS:
      return state.set('installers', fromJS(action.installers))
    // ProjectTags input
    case `${types.CREATE_NEW_PROJECT_TAG}_FAILURE`:
      return state.setIn(['createProjectTag', 'error'], action.error.message)
    case types.CREATE_NEW_PROJECT_TAG:
      const newTag = action.data.tag
      return state
        .setIn(['createProjectTag', 'success'], action.data.message)
        .set(
          'availableProjectTags',
          state.get('availableProjectTags').push(
            fromJS({
              value: newTag.id,
              label: newTag.name,
            })
          )
        )
        .set(
          'projectTags',
          state.get('projectTags').push(
            fromJS({
              value: newTag.id,
              label: newTag.name,
            })
          )
        )
    case types.RESET_CREATE_PROJECT_TAG_STATUS_MESSAGES:
      return state.setIn(['createProjectTag', 'error'], null).setIn(['createProjectTag', 'success'], null)
    case types.SET_AVAILABLE_PROJECT_TAGS:
      return state.set('availableProjectTags', fromJS(action.projectTags))
    case types.SET_PROJECT_TAGS:
      return state.set('projectTags', fromJS(action.projectTags))
    // FinanceTags input
    case `${types.CREATE_NEW_FINANCE_TAG}_FAILURE`:
      return state.setIn(['createFinanceTag', 'error'], action.error.message)
    case types.CREATE_NEW_FINANCE_TAG:
      const newFinanceTag = action.data.tag
      return state
        .setIn(['createFinanceTag', 'success'], action.data.message)
        .set(
          'availableFinanceTags',
          state.get('availableFinanceTags').push(
            fromJS({
              value: newFinanceTag.id,
              label: newFinanceTag.name,
            })
          )
        )
        .set(
          'financeTags',
          state.get('financeTags').push(
            fromJS({
              value: newFinanceTag.id,
              label: newFinanceTag.name,
            })
          )
        )
    case types.RESET_CREATE_FINANCE_TAG_STATUS_MESSAGES:
      return state.setIn(['createFinanceTag', 'error'], null).setIn(['createFinanceTag', 'success'], null)
    case types.SET_AVAILABLE_FINANCE_TAGS:
      return state.set('availableFinanceTags', fromJS(action.financeTags))
    case types.SET_FINANCE_TAGS:
      return state.set('financeTags', fromJS(action.financeTags))
    // Locations input
    case types.SET_LOCATION:
      return state.set('location', fromJS(action.location))
    case types.SET_AVAILABLE_LOCATIONS:
      return state.set('availableLocations', fromJS(action.locations))
    case types.CREATE_NEW_LOCATION + '_FAILURE':
      return state.setIn(['createLocation', 'error'], action.error.message)
    case types.CREATE_NEW_LOCATION_ELEMENT + '_FAILURE':
      return state.setIn(['createLocationElementMessage'], action.data.message)
                  .setIn(['createLocationElementSuccess'], action.data.success)
    case types.CREATE_NEW_LOCATION:
      const newLocation = action.data.location
      // insert new one in front of the list
      const availableLocations = state
        .setIn(['createLocation', 'success'], action.data.message)
        .get('availableLocations')
        .unshift(
          fromJS({
            value: newLocation.id,
            label: newLocation.name,
          })
        )
      const location = fromJS({
        value: newLocation.id,
        label: newLocation.name,
      })
      return state
        .setIn(['createLocation', 'success'], action.data.message)
        .set('availableLocations', availableLocations)
        .set('location', location)
    case types.UPDATE_LOCATION_ELEMENT:
      return state.updateIn(['elements'], elements => action.data.success ? elements.concat(fromJS(action.data.element)) : elements)
                  .updateIn(['selected_elements'], selected_elements => action.data.success ? selected_elements.concat(fromJS(action.data.element)) : selected_elements)
                  .setIn(['createLocationElementMessage'], action.data.message)
                  .setIn(['createLocationElementSuccess'], action.data.success)
    case types.RESET_CREATE_LOCATION_STATUS_MESSAGES:
      return state.setIn(['createLocation', 'error'], null).setIn(['createLocation', 'success'], null)
    case types.RESET_CREATE_LOCATION_ELEMENT_STATUS_MESSAGES:
      return state.setIn(['createLocationElementMessage'], null)
                  .setIn(['createLocationElementSuccess'], null)
    case types.SET_ELEMENT_GROUPS:
      return state.setIn(['element_groups'], fromJS(action.element_groups))
    case types.LOAD_INSTALLERS_SUCCESS:
      return state.setIn(['availableInstallers'], fromJS(action.data.installers))
    case types.LOAD_ELEMENTS_SUCCESS:
      return state.setIn(['elements'], fromJS(action.data.element_ids_options))
    case types.SET_SELECTED_ELEMENTS:
      return state.setIn(['selected_elements'], fromJS(action.elements))
    default:
      return state
  }
}

export default reducer
