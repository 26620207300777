import * as React from 'react'
import FormGroup from '../installers_selector_app/form_group'
import I18n from '../../../../libs/i18n'

type CreateProjectTagFormProps = {
  data: {
    name: string
    description: string
  }
  setData: (data: any) => void
  formRef: any
  namePrefix?: string
}
export default function CreateProjectTagForm(props: CreateProjectTagFormProps) {
  const { data, setData, formRef, namePrefix } = props
  return (
    <form className={`${namePrefix} default-form`} action="#" method="POST" ref={formRef}>
      <ul>
        <FormGroup label={I18n.t('activerecord.attributes.tags/base.name')} name={`${namePrefix}_name`}>
          <input
            id={`${namePrefix}_name`}
            className="form-control"
            type="text"
            required
            name="name"
            value={data.name}
            onChange={(e) =>
              setData({
                ...data,
                name: e.target.value,
              })
            }
          />
        </FormGroup>
        <FormGroup label={I18n.t('activerecord.attributes.tags/base.description')} name={`${namePrefix}_description`}>
          <input
            id={`${namePrefix}_description`}
            className="form-control"
            type="text"
            name="description"
            value={data.description}
            onChange={(e) =>
              setData({
                ...data,
                description: e.target.value,
              })
            }
          />
        </FormGroup>
      </ul>
    </form>
  )
}
