import * as React from 'react'
import { useEffect } from 'react'
import connect from './connect'
import SelectorWithPopup from '../selector_with_popup'
import CreateProjectTagForm from '../project_tags_selector_app/create_project_tag_form'

type FinanceTagsSelectorProps = {
  name: string
  value: { value: string; label: string }[]
  options: { value: string; label: string }[]
  canCreateNew: boolean
  buttonTitle: string
  windowTitle: string

  // Redux props
  isFetching: boolean
  setAvailableFinanceTags: (tags: any[]) => void
  availableFinanceTags: any[]
  financeTags: any[]
  setFinanceTags: (tags: any[]) => void
  createNewFinanceTag: (data: any) => void
  createFinanceTagError: string
  createFinanceTagSuccess: string
  resetCreateFinanceTagStatusMessages: () => void
}

function FinanceTagsSelector(props: FinanceTagsSelectorProps) {
  const {
    value,
    options,
    name,
    isFetching,
    canCreateNew,
    financeTags,
    setFinanceTags,
    setAvailableFinanceTags,
    availableFinanceTags,
    createNewFinanceTag,
    createFinanceTagSuccess,
    createFinanceTagError,
    resetCreateFinanceTagStatusMessages,
    buttonTitle,
    windowTitle,
  } = props

  const [data, setData] = React.useState({
    name: '',
    description: '',
    type: 'Tags::FinanceTag',
  })

  useEffect(() => {
    setAvailableFinanceTags(options)
    setFinanceTags(value)
  }, [])

  return (
    <SelectorWithPopup
      name={name}
      values={financeTags}
      setValues={setFinanceTags}
      options={availableFinanceTags}
      canCreateNew={canCreateNew}
      isLoading={isFetching}
      renderForm={(formRef) => (
        <CreateProjectTagForm data={data} setData={setData} formRef={formRef} namePrefix="finance_tags" />
      )}
      onSubmit={() => createNewFinanceTag(data)}
      resetStatusMessages={resetCreateFinanceTagStatusMessages}
      errorMessage={createFinanceTagError}
      successMessage={createFinanceTagSuccess}
      buttonTitle={buttonTitle}
      windowTitle={windowTitle}
      windowId="create-finance-tag"
    />
  )
}

export default connect(FinanceTagsSelector)
