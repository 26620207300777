import { stringify } from 'query-string'

const INIT_COMPANY_LIST = 'projects/form/INIT_COMPANY_LIST'
const INIT_GROUP_SELECTOR_DATA = 'projects/form/INIT_GROUP_SELECTOR_DATA'
const LOAD_PROJECT_GROUPS = 'projects/form/LOAD_PROJECT_GROUPS'
const LOAD_ADDITIONAL_FORM_FIELDS = 'projects/form/LOAD_ADDITIONAL_FORM_FIELDS'

const CREATE_NEW_SERVICE_COMPANY = 'projects/form/CREATE_NEW_SERVICE_COMPANY'
const RESET_CREATE_SERVICE_COMPANY_STATUS_MESSAGES = 'projects/form/RESET_CREATE_SERVICE_COMPANY_STATUS_MESSAGES'
const SET_AVAILABLE_VENDORS = 'projects/form/SET_AVAILABLE_VENDORS'
const SET_VENDORS = 'projects/form/SET_VENDORS'

const CREATE_NEW_INSTALLER = 'projects/form/CREATE_NEW_INSTALLER'
const RESET_CREATE_INSTALLER_STATUS_MESSAGES = 'projects/form/RESET_CREATE_INSTALLER_STATUS_MESSAGES'
const SET_AVAILABLE_INSTALLERS = 'projects/form/SET_AVAILABLE_INSTALLERS'
const SET_INSTALLERS = 'projects/form/SET_INSTALLERS'

const CREATE_NEW_PROJECT_TAG = 'projects/form/CREATE_NEW_PROJECT_TAG'
const RESET_CREATE_PROJECT_TAG_STATUS_MESSAGES = 'projects/form/RESET_CREATE_PROJECT_TAG_STATUS_MESSAGES'
const SET_AVAILABLE_PROJECT_TAGS = 'projects/form/SET_AVAILABLE_PROJECT_TAGS'
const SET_PROJECT_TAGS = 'projects/form/SET_PROJECT_TAGS'
const CREATE_NEW_FINANCE_TAG = 'projects/form/CREATE_NEW_FINANCE_TAG'
const RESET_CREATE_FINANCE_TAG_STATUS_MESSAGES = 'projects/form/RESET_CREATE_FINANCE_TAG_STATUS_MESSAGES'
const SET_AVAILABLE_FINANCE_TAGS = 'projects/form/SET_AVAILABLE_FINANCE_TAGS'
const SET_FINANCE_TAGS = 'projects/form/SET_FINANCE_TAGS'

const CREATE_NEW_LOCATION = 'projects/form/CREATE_NEW_LOCATION'
const RESET_CREATE_LOCATION_STATUS_MESSAGES = 'projects/form/RESET_CREATE_LOCATION_STATUS_MESSAGES'
const RESET_CREATE_LOCATION_ELEMENT_STATUS_MESSAGES = 'projects/form/RESET_CREATE_LOCATION_ELEMENT_STATUS_MESSAGES'
const SET_AVAILABLE_LOCATIONS = 'projects/form/SET_AVAILABLE_LOCATIONS'
const SET_LOCATION = 'projects/form/SET_LOCATION'

const SET_ELEMENT_GROUPS = 'projects/form/SET_ELEMENT_GROUPS'
const LOAD_ELEMENTS = 'projects/form/LOAD_ELEMENTS'
const LOAD_ELEMENTS_SUCCESS = `${LOAD_ELEMENTS}_SUCCESS`
const LOAD_INSTALLERS = 'projects/form/LOAD_INSTALLERS'
const LOAD_INSTALLERS_SUCCESS = `${LOAD_INSTALLERS}_SUCCESS`
const SET_SELECTED_ELEMENTS = 'projects/form/SET_SELECTED_ELEMENTS'
const CREATE_NEW_LOCATION_ELEMENT = 'projects/form/CREATE_NEW_LOCATION_ELEMENT'
const UPDATE_LOCATION_ELEMENT = 'projects/form/UPDATE_LOCATION_ELEMENT'
const LOAD_ADDITIONAL_FORM_FIELDS_LOCATION_ELEMENT = 'projects/form/LOAD_ADDITIONAL_FORM_FIELDS_LOCATION_ELEMENT'

export const types = {
  INIT_COMPANY_LIST,
  INIT_GROUP_SELECTOR_DATA,
  LOAD_PROJECT_GROUPS,
  LOAD_ADDITIONAL_FORM_FIELDS,
  CREATE_NEW_SERVICE_COMPANY,
  RESET_CREATE_SERVICE_COMPANY_STATUS_MESSAGES,
  SET_AVAILABLE_VENDORS,
  SET_VENDORS,
  CREATE_NEW_INSTALLER,
  RESET_CREATE_INSTALLER_STATUS_MESSAGES,
  SET_AVAILABLE_INSTALLERS,
  SET_INSTALLERS,
  CREATE_NEW_PROJECT_TAG,
  RESET_CREATE_PROJECT_TAG_STATUS_MESSAGES,
  SET_AVAILABLE_PROJECT_TAGS,
  SET_PROJECT_TAGS,
  CREATE_NEW_FINANCE_TAG,
  RESET_CREATE_FINANCE_TAG_STATUS_MESSAGES,
  SET_AVAILABLE_FINANCE_TAGS,
  SET_FINANCE_TAGS,
  CREATE_NEW_LOCATION,
  RESET_CREATE_LOCATION_STATUS_MESSAGES,
  SET_AVAILABLE_LOCATIONS,
  SET_LOCATION,
  SET_ELEMENT_GROUPS,
  LOAD_ELEMENTS,
  LOAD_ELEMENTS_SUCCESS,
  SET_SELECTED_ELEMENTS,
  CREATE_NEW_LOCATION_ELEMENT,
  LOAD_ADDITIONAL_FORM_FIELDS_LOCATION_ELEMENT,
  UPDATE_LOCATION_ELEMENT,
  RESET_CREATE_LOCATION_ELEMENT_STATUS_MESSAGES,
  LOAD_INSTALLERS,
  LOAD_INSTALLERS_SUCCESS,
}

export const initCompanyList = (data) => ({ type: INIT_COMPANY_LIST, ...data })

export const initGroupSelectorData = (data) => ({ type: INIT_GROUP_SELECTOR_DATA, ...data })

export const loadProjectGroups = (companyId) => ({
  type: LOAD_PROJECT_GROUPS,
  request: {
    url: `/dropdown_options/project-groups-by-company.json?meta[company_id]=${companyId}`,
    method: 'get',
  },
  companyId,
})

export const loadAdditionalFormFields = (data) => {
  const qParams = stringify(data, { arrayFormat: 'bracket' })

  return {
    type: LOAD_ADDITIONAL_FORM_FIELDS,
    request: {
      url: `/project_tickets/form-fields.json?${qParams}`,
      method: 'get',
    },
    groupId: data.group_id,
  }
}

export const createNewServiceCompany = (data) => ({
  type: CREATE_NEW_SERVICE_COMPANY,
  request: {
    url: '/vendors.json',
    method: 'post',
    body: { vendor: data },
  },
})

export const resetCreateServiceCompanyStatusMessages = (data) => ({ type: RESET_CREATE_SERVICE_COMPANY_STATUS_MESSAGES, ...data })

export const setAvailableVendors = (vendors) => ({
  type: SET_AVAILABLE_VENDORS,
  vendors,
})

export const setVendors = (vendors) => ({
  type: SET_VENDORS,
  vendors,
})


export const createNewInstaller = (data) => ({
  type: CREATE_NEW_INSTALLER,
  request: {
    url: '/users/simple_create.json',
    method: 'post',
    body: { users_installer: data },
  },
})
export const resetCreateInstallerStatusMessages = (data) => ({ type: RESET_CREATE_INSTALLER_STATUS_MESSAGES, ...data })

export const setAvailableInstallers = (installers) => ({
  type: SET_AVAILABLE_INSTALLERS,
  installers,
})

export const setInstallers = (installers) => ({
  type: SET_INSTALLERS,
  installers,
})

export const createNewProjectTag = (data) => ({
  type: CREATE_NEW_PROJECT_TAG,
  request: {
    url: '/tags.json',
    method: 'post',
    body: { tag: data },
  },
})

export const resetCreateProjectTagStatusMessages = (data) => ({ type: RESET_CREATE_PROJECT_TAG_STATUS_MESSAGES, ...data })

export const setAvailableProjectTags = (projectTags) => ({
  type: SET_AVAILABLE_PROJECT_TAGS,
  projectTags,
})

export const setProjectTags = (projectTags) => ({
  type: SET_PROJECT_TAGS,
  projectTags,
})

export const createNewFinanceTag = (data) => ({
  type: CREATE_NEW_FINANCE_TAG,
  request: {
    url: '/tags.json',
    method: 'post',
    body: { tag: data },
  },
})

export const resetCreateFinanceTagStatusMessages = (data) => ({ type: RESET_CREATE_FINANCE_TAG_STATUS_MESSAGES, ...data })

export const setAvailableFinanceTags = (financeTags) => ({
  type: SET_AVAILABLE_FINANCE_TAGS,
  financeTags,
})

export const setFinanceTags = (financeTags) => ({
  type: SET_FINANCE_TAGS,
  financeTags,
})

export const createNewLocation = (data) => ({
  type: CREATE_NEW_LOCATION,
  request: {
    url: '/locations.json',
    method: 'post',
    body: { location: data },
  },
})

export const createNewLocationElement = (data) => ({
  type: CREATE_NEW_LOCATION_ELEMENT,
  request: {
    url: `/locations/${data.location_id}/elements.json`,
    method: 'post',
    body: { elements_location_element: data },
  },
})

export const loadAdditionalFormFieldsLocationElement = (data) => {
  return {
    type: LOAD_ADDITIONAL_FORM_FIELDS_LOCATION_ELEMENT,
    request: {
      url: `/elements/form-fields?element_id=${data}`,
      method: 'get',
    }
  }
}

export const updateLocationElement = (data, formDataObj) => {
  return {
    type: UPDATE_LOCATION_ELEMENT,
    request: {
      url: `/elements/${data.element_id}.json`,
      method: 'patch',
      body: { ...data, elements_location_element: formDataObj.elements_location_element },
    }
  }
}

export const resetCreateLocationStatusMessages = (data) => ({ type: RESET_CREATE_LOCATION_STATUS_MESSAGES, ...data })
export const resetCreateLocationElementStatusMessages = (data) => ({ type: RESET_CREATE_LOCATION_ELEMENT_STATUS_MESSAGES, ...data })

export const setAvailableLocations = (locations) => ({
  type: SET_AVAILABLE_LOCATIONS,
  locations,
})

export const setLocation = (location) => ({
  type: SET_LOCATION,
  location,
})

export const setElementGroups = (element_groups) => ({
  type: SET_ELEMENT_GROUPS,
  element_groups,
})

export const setSelectedElements = (elements) => ({
  type: SET_SELECTED_ELEMENTS,
  elements,
})

export const dynamicLoadInstallers = (vendors, companyId: number) => {
  const vendor_ids = vendors.map(vendor => vendor.value)
  const url = '/dropdown_options/installers-by-vendors'
  const queryString = vendor_ids.length ? vendor_ids.map((id) => `vendor_ids[]=${id}`).join('&') : ''
  const fullUrl = `${url}?${queryString}&meta[company_id]=${companyId}`
  return {
    type: LOAD_INSTALLERS,
    request: {
      url: fullUrl,
      method: 'get',
      options: {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    },
  }
}

export const loadElements = (url, element_group_ids = []) => {
  const queryString = element_group_ids.length ? element_group_ids.map((id) => `group_ids[]=${id}`).join('&') : ''
  const fullUrl = queryString ? url + `?${queryString}` : url
  return {
    type: LOAD_ELEMENTS,
    request: {
      url: fullUrl,
      method: 'get',
      options: {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    },
  }
}
